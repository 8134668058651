var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a",
        {
          staticClass: "db mt3",
          attrs: { href: "https://jsoneditoronline.org/", target: "_blank" },
        },
        [_vm._v(" Online JSON editor ")]
      ),
      _c("p", { staticClass: "gray mt2" }, [
        _vm._v("Paste featured JSON here!"),
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.featuredJson,
            expression: "featuredJson",
          },
        ],
        staticClass: "bg-adori-gray mt2 white pa2 code",
        attrs: { rows: "20", cols: "100" },
        domProps: { value: _vm.featuredJson },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.featuredJson = $event.target.value
          },
        },
      }),
      _c("br"),
      _c("BaseButtonRed", {
        staticClass: "mt3",
        attrs: { text: "Upload", onClick: _vm.saveFeatured },
      }),
      _vm.error !== ""
        ? _c("p", { staticClass: "di ml3 f3 bg-red pa2" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
      _vm.success
        ? _c("p", { staticClass: "di ml3 f3 bg-green pa2" }, [
            _vm._v("Success"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }