












import { Vue, Component } from 'vue-property-decorator'
import AdoriService from '@/services/adori'

@Component
export default class ViewFeatured extends Vue {
  featuredJson = ''
  error = ''
  success = false

  async created() {
    this.featuredJson = JSON.stringify(await AdoriService.fetchFeaturedContent(), null, '\t')
  }

  async mounted() {
    window.scrollTo(0, 0)
  }

  async saveFeatured() {
    const res: any = await AdoriService.updateFeaturedContent(this.featuredJson)
    if (res.response && res.response.status !== 200) {
      this.error = 'Error: ' + res.response.statusText
    } else {
      this.success = true
      setTimeout(() => {
        this.success = false
      }, 5000)
    }
  }
}
